import React, { useEffect, useState } from "react";
import ModalInputField from "./ModalInputField";
import { ModalFooter, ModalFooterActions } from "@twilio-paste/modal";
import { ModalBody, Box } from "@twilio-paste/core";
import { Button } from "@twilio-paste/button";

import { ActionName } from "../../types";
import ConvoModal from "./ConvoModal";

interface ConversationTenantProspectModalProps {
    isModalOpen: boolean;
    onCancel: () => void;
    onSave: (phone: string, name: string, property: string) => Promise<void>;
}

const ConversationTenantProspectModal: React.FC<ConversationTenantProspectModalProps> = (
    props: ConversationTenantProspectModalProps
) => {
    const [phone, setPhone] = useState("");
    //const [title, setTitle] = useState(props.title);
    const [name, setName] = useState("");
    const [property, setProperty] = useState("");
    const [error, setError] = useState("");
    const [isFormDirty, setFormDirty] = useState(false);
    const [isLoading, setLoading] = useState(false);

    const onCancel = () => {
        setError("");
        setFormDirty(false);
        props.onCancel();
    };

    const onSave = async () => {
        if (phone.length < 1 || name.length < 1 || property.length < 1) {
            return;
        }

        setError("");

        try {
            await props.onSave(phone, name, property);
        } catch (e) {
            setError((e as Error).message ?? "");
        }

        setLoading(false);
    };

    const onSubmit = (e: React.FormEvent<HTMLElement>) => {
        e.preventDefault();

        if (isLoading) {
            return;
        }

        setLoading(true);
        onSave();
    };
    const onKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
        if (isLoading) {
            return;
        }

        if (e.key === "Escape") {
            e.preventDefault();
            onCancel();
        }
    };

    const isBadData = (phone.length < 1 || name.length < 1 || property.length < 1);

    return (
        <>
            <ConvoModal
                title={"New Tenant Prospect Conversation"}
                isModalOpen={props.isModalOpen}
                handleClose={onCancel}
                modalBody={
                    <ModalBody>
                        <Box as="form" onSubmit={onSubmit} onKeyDown={onKeyDown}>
                            <ModalInputField
                                isFocused={true}
                                label="Phone number"
                                input={phone}
                                placeholder="123456789012"
                                onChange={(s) => {
                                    setPhone(s);
                                    setFormDirty(s.length === 0);
                                }}
                                error={
                                    error
                                        ? error
                                        : isFormDirty && !phone
                                            ? "Add a phone number."
                                            : ""
                                }
                                // error_text="Enter a valid phone number."
                                help_text="The phone number of the prospect."
                                prefixType="SMS"
                            />
                            <ModalInputField
                                isFocused={true}
                                label="Name"
                                input={name}
                                placeholder="name"
                                onChange={(s) => {
                                    setName(s);
                                    setFormDirty(s.length === 0);
                                }}
                                error={
                                    error
                                        ? error
                                        : isFormDirty && !name
                                            ? "Add a name."
                                            : ""
                                }
                                help_text="The name of the prospect."
                            />
                            <ModalInputField
                                isFocused={true}
                                label="Property"
                                input={property}
                                placeholder="property address"
                                onChange={(s) => {
                                    setProperty(s);
                                    setFormDirty(s.length === 0);
                                }}
                                error={
                                    error
                                        ? error
                                        : isFormDirty && !property
                                            ? "Add a property."
                                            : ""
                                }
                                help_text="The property address."
                            />
                        </Box>
                    </ModalBody>
                }
                modalFooter={
                    <ModalFooter>
                        <ModalFooterActions>
                            <Button
                                disabled={isLoading}
                                variant="secondary"
                                onClick={onCancel}
                            >
                                Cancel
                            </Button>
                            <Button
                                disabled={isBadData || isLoading}
                                variant="primary"
                                onClick={onSave}
                            >
                                {ActionName.Save}
                            </Button>
                        </ModalFooterActions>
                    </ModalFooter>
                }
            />
        </>
    );
};

export default ConversationTenantProspectModal;
