import { Avatar } from "./Avatar";
import { Text } from "@twilio-paste/core";
import { Menu, MenuButton, useMenuState, MenuItem } from "@twilio-paste/menu";
import { ChevronDownIcon } from "@twilio-paste/icons/esm/ChevronDownIcon";
import React, { useMemo, useState } from "react";
import styles from "../styles";
import { Client, ConnectionState, User } from "@twilio/conversations";
import UserProfileModal from "./modals/UserProfileModal";
import { readUserProfile } from "../api";
import { AppLogo, LOGO_SUB_TITLE, LOGO_TITLE } from "../branding";
import { config } from "dotenv";
import useWindowDimensions from "../hooks/useWindowDimensions";
import CallsModal from "./modals/CallsModal";

type AppHeaderProps = {
    user: string;
    onSignOut: () => void;
    connectionState: ConnectionState;

    listHidden: boolean;
    client?: Client;
};
const AppHeader: React.FC<AppHeaderProps> = ({
    onSignOut,
    connectionState,
    client,
    listHidden
}) => {
    const menu = useMenuState();
    const { height, width } = useWindowDimensions();

    const [showUserProfileModal, setUserProfileModal] = useState(false);

    const [showCallsModal, setCallsModal] = useState(false);

    const [userProfile, setUserProfile] = useState<User | undefined>(undefined);

    const handleUserProfileModalClose = () => setUserProfileModal(false);

    const handleCallsModalClose = () => setCallsModal(false);

    const username = localStorage.getItem("username") ?? "";
    const friendlyName = localStorage.getItem("friendlyName") ?? "";

    const label: "online" | "connecting" | "offline" = useMemo(() => {
        switch (connectionState) {
            case "connected":
                return "online";
            case "connecting":
                return "connecting";
            default:
                return "offline";
        }
    }, [connectionState]);

    const handleUserProfileModalOpen = async () => {
        const userProfileTemp = await readUserProfile(username, client);
        setUserProfile(userProfileTemp);
        setUserProfileModal(true);
    };

    const handleCallsModalOpen = async () => {
        const userProfileTemp = await readUserProfile(username, client);
        setUserProfile(userProfileTemp);
        setCallsModal(true);
    };


    return (
        <div style={{
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: "#0059A1",
            padding: "20px 25px",
            marginLeft: listHidden ? 70 : 320
        }}>
            <div style={styles.flex}>
                <img src="/logo_transparent.png" alt="image" height={40} />
                {/*<div style={styles.appLogoWrapper}>*/}
                {/*  */}{/*<AppLogo />*/}
                {/*</div>*/}
                {/*<div style={styles.appLogoTitle}>*/}
                {/*  {LOGO_TITLE}*/}
                {/*  <div style={styles.appLogoSubTitle}>{LOGO_SUB_TITLE}</div>*/}
                {/*</div>*/}
            </div>
            <div style={styles.userTile}>
                <Avatar name={username} />
                <div
                    style={{
                        padding: "0 10px",
                    }}
                >
                    <Text title={friendlyName ?? username} as="span" style={styles.userName}>
                        {friendlyName ?? username}
                    </Text>
                    <Text
                        as="span"
                        color={
                            label === "online"
                                ? "colorTextPrimaryWeak"
                                : label === "connecting"
                                    ? "colorTextIconBusy"
                                    : "colorTextWeaker"
                        }
                        style={styles.userStatus}
                    >
                        {label === "online"
                            ? "Online"
                            : label === "connecting"
                                ? "Connecting…"
                                : "Offline"}
                    </Text>
                </div>
                <MenuButton {...menu} variant="link" size="reset">
                    <ChevronDownIcon
                        color="colorTextInverse"
                        decorative={false}
                        title="Settings"
                    />
                </MenuButton>
                <Menu {...menu} aria-label="Preferences">
                    <MenuItem {...menu} onClick={onSignOut}>
                        Sign Out
                    </MenuItem>
                    <MenuItem {...menu} onClick={handleUserProfileModalOpen}>
                        User Profile
                    </MenuItem>
                    <MenuItem {...menu} onClick={handleCallsModalOpen}>
                        Calls
                    </MenuItem>
                </Menu>
            </div>
            {showUserProfileModal && (
                <UserProfileModal
                    isModalOpen={showUserProfileModal}
                    handleClose={handleUserProfileModalClose}
                    user={userProfile}
                ></UserProfileModal>
            )}
            {showCallsModal && (
                <CallsModal
                    isModalOpen={showCallsModal}
                    handleClose={handleCallsModalClose}
                    user={userProfile}
                ></CallsModal>
            )}
        </div>
    );
};

export default AppHeader;
