import React, { useEffect, useState } from "react";
import { Client } from "@twilio/conversations";
import { ChevronDoubleLeftIcon } from "@twilio-paste/icons/esm/ChevronDoubleLeftIcon";
import { Box, Input } from "@twilio-paste/core";
import { ChevronDoubleRightIcon } from "@twilio-paste/icons/esm/ChevronDoubleRightIcon";

import CreateConversationButton from "./CreateConversationButton";
import ConversationsList from "./ConversationsList";
import styles from "../../styles";

import { useDispatch } from "react-redux";
import { filterConversations } from "./../../store/action-creators";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import ConversationsListCollasped from "./ConversationsListCollasped";
import CreateTenantProspectConversationButton from "./CreateTenantProspectConversationButton";

interface ConvosContainerProps {
    listHidden: boolean;
    setListHidden: any;
    client?: Client;
}

const ConversationsContainer: React.FC<ConvosContainerProps> = (
    props: ConvosContainerProps
) => {
    const { height, width } = useWindowDimensions();
    const dispatch = useDispatch();

    const handleSearch = (searchString: string) => {
        dispatch(filterConversations(searchString));
    };

    useEffect(() => {
        if (width <= 768) {
            props.setListHidden(true);
        }
    }, []);

    return (
        <Box
            style={
                props.listHidden
                    ? { ...styles.convosWrapper, ...styles.collapsedList }
                    : styles.convosWrapper
            }
        >
            <Box style={styles.newConvoButton}>
                <CreateConversationButton
                    client={props.client}
                    collapsed={props.listHidden}
                />
                <CreateTenantProspectConversationButton
                    client={props.client}
                    collapsed={props.listHidden}
                />
                <Box marginTop="space60" style={{ display: props.listHidden ? "none" : "inherit" }} >
                    <Input
                        aria-describedby="convo_string_search"
                        id="convoString"
                        name="convoString"
                        type="text"
                        placeholder="Search"
                        onChange={(e) => handleSearch(e.target.value)}
                        required
                        autoFocus
                    />
                </Box>
            </Box>
            <Box style={styles.convoList}>
                {!props.listHidden ? <ConversationsList /> : <ConversationsListCollasped />}
            </Box>
            <Box style={styles.collapseButtonBox}>
                <Box
                    paddingTop="space30"
                    style={{
                        paddingLeft: 10,
                        paddingRight: 10,
                    }}
                    onClick={() => props.setListHidden(!props.listHidden)}
                >
                    {props.listHidden ? (
                        <ChevronDoubleRightIcon decorative={false} title="Collapse" />
                    ) : (
                        <ChevronDoubleLeftIcon decorative={false} title="Collapse" />
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default ConversationsContainer;
