import { LogoTwilioIcon } from "@twilio-paste/icons/esm/LogoTwilioIcon";
import React from "react";
import { IconSize, TextColor } from "@twilio-paste/style-props";

export const LOGO_TITLE = "Forge";
export const LOGO_SUB_TITLE = "Forge App";
export const APP_TITLE = "Forge";

interface LogoTwilioIconProps {
  decorative?: boolean;
  color?: TextColor | undefined;
  size?: IconSize | undefined;
  title?: string;
}

export const AppLogo: React.FC<LogoTwilioIconProps> = ({
    decorative = false,
    color = "colorTextBrandHighlight",
    size = "sizeIcon40",
    title = "app logo",
}) => (
  //<LogoTwilioIcon
  //  decorative={decorative}
  //  color={color}
  //  size={size}
  //  title={title}
  ///>

    <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="509.000000pt" height="199.000000pt" viewBox="0 0 509.000000 199.000000" preserveAspectRatio="xMidYMid meet">
        <metadata>
            Created by potrace 1.10, written by Peter Selinger 2001-2011
        </metadata>
        <g transform="translate(0.000000,199.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
            <path d="M375 1565 c-27 -13 -57 -30 -65 -37 -26 -22 -67 -82 -75 -108 -5 -20 -2 -17 15 10 37 60 81 98 137 121 46 19 65 21 134 15 43 -3 116 -8 162 -11 81 -4 81 -4 -38 15 -163 26 -209 25 -270 -5z" />
            <path d="M415 1521 c-78 -19 -136 -68 -171 -146 -25 -55 -26 -57 -22 -293 3 -221 4 -240 25 -278 12 -22 38 -55 58 -72 67 -59 83 -62 354 -62 216 0 251 3 286 19 59 27 101 66 126 118 22 45 22 56 21 290 -2 272 -6 290 -70 356 -66 68 -111 77 -360 76 -119 -1 -230 -4 -247 -8z m515 -41 c41 -15 95 -63 116 -102 10 -20 14 -67 14 -174 l0 -147 -77 78 c-145 145 -167 153 -217 81 -12 -18 -14 -18 -46 12 -43 41 -83 43 -107 5 -9 -16 -18 -30 -20 -32 -1 -2 -19 11 -39 28 -28 26 -42 31 -63 26 -15 -3 -71 -51 -130 -110 -57 -58 -106 -105 -108 -105 -3 0 -3 71 -1 158 3 155 4 160 31 199 31 45 94 84 152 94 67 12 455 4 495 -11z m-308 -347 c114 -113 124 -132 80 -161 -21 -14 -26 -11 -112 74 l-90 88 -83 -82 c-53 -53 -90 -82 -104 -82 -28 0 -47 33 -34 58 15 28 207 212 221 212 7 0 62 -48 122 -107z m162 -2 c113 -113 121 -130 78 -159 -22 -14 -26 -11 -132 94 -109 107 -126 134 -98 162 24 24 45 10 152 -97z m169 0 c59 -59 107 -111 107 -115 0 -4 -5 -16 -10 -27 -21 -39 -52 -22 -162 88 -60 59 -108 112 -108 117 0 16 32 46 50 46 9 0 64 -49 123 -109z m-421 -96 l27 -24 -28 -30 -29 -31 -30 29 -29 29 26 26 c14 14 28 26 31 26 3 0 17 -11 32 -25z m150 -95 c21 0 68 42 68 60 0 6 14 -2 30 -19 17 -16 39 -33 51 -36 24 -8 67 18 77 46 7 18 10 17 43 -13 34 -31 89 -47 89 -27 0 5 5 9 11 9 8 0 10 -23 7 -69 -3 -59 -8 -76 -34 -108 -17 -21 -50 -48 -74 -60 -42 -22 -55 -23 -287 -23 -265 0 -290 4 -346 58 -48 45 -67 93 -67 169 l0 68 23 -26 c30 -35 66 -30 114 16 25 24 39 32 42 23 7 -26 55 -68 77 -68 26 0 64 43 64 72 0 15 10 10 47 -26 26 -25 55 -46 65 -46z" />
            <path d="M1010 1492 c55 -38 80 -65 104 -114 31 -62 33 -107 11 -292 -8 -71 -14 -131 -12 -133 7 -7 47 263 47 318 0 39 -8 70 -26 107 -27 54 -90 116 -127 125 -21 5 -20 4 3 -11z" />
            <path d="M2080 1431 c-30 -12 -55 -32 -74 -59 -25 -36 -29 -51 -34 -134 -8 -163 17 -227 105 -272 49 -25 150 -21 204 7 72 38 89 79 89 220 0 78 -5 129 -14 150 -39 91 -167 132 -276 88z m187 -38 c61 -29 73 -61 73 -196 0 -95 -4 -125 -17 -148 -38 -64 -155 -88 -239 -50 -68 31 -74 46 -74 201 1 91 5 140 13 150 54 67 157 85 244 43z" />
            <path d="M2112 1360 c-44 -27 -52 -54 -52 -167 0 -71 4 -102 17 -124 17 -29 67 -59 98 -59 24 0 83 38 95 60 6 10 10 69 10 130 l0 112 -34 34 c-40 40 -83 45 -134 14z m120 -38 c14 -14 18 -38 19 -127 1 -101 0 -110 -21 -127 -31 -25 -94 -24 -120 2 -18 18 -20 33 -20 128 0 113 5 128 50 145 24 10 73 -2 92 -21z" />
            <path d="M3057 1435 c-44 -15 -83 -48 -103 -87 -20 -39 -20 -268 1 -308 17 -32 67 -74 103 -85 39 -11 135 -7 172 9 48 20 90 66 101 112 13 59 11 130 -5 143 -9 8 -46 11 -103 9 l-88 -3 -3 -44 c-4 -51 8 -63 56 -55 27 5 32 3 32 -13 0 -49 -64 -80 -117 -58 -16 7 -33 20 -37 30 -3 9 -6 63 -6 120 0 100 1 104 26 124 44 35 115 22 136 -24 10 -22 18 -25 58 -25 26 0 49 2 52 6 3 3 2 22 -3 42 -20 92 -161 147 -272 107z m183 -45 c37 -19 60 -45 60 -69 0 -18 -48 -12 -62 7 -23 32 -62 52 -101 52 -31 0 -46 -7 -73 -34 l-34 -34 0 -115 c0 -99 3 -118 20 -140 24 -30 81 -51 116 -43 38 10 74 54 74 90 0 39 -16 56 -52 56 -17 0 -28 5 -28 14 0 11 17 15 66 18 36 1 68 1 70 -1 12 -12 3 -129 -12 -149 -59 -85 -254 -80 -299 8 -13 26 -16 56 -13 162 l3 130 34 28 c62 53 152 60 231 20z" />
            <path d="M1552 1199 l3 -242 50 0 50 0 3 97 3 96 104 0 105 0 0 50 0 50 -105 0 -105 0 0 45 0 44 123 3 c120 3 122 3 125 27 2 12 1 34 -2 47 l-6 24 -175 0 -175 0 2 -241z m318 191 c0 -19 -7 -20 -102 -20 -120 0 -138 -9 -138 -75 0 -69 15 -79 119 -83 70 -3 91 -7 91 -18 0 -11 -19 -14 -87 -14 -115 0 -123 -7 -123 -112 0 -77 0 -78 -25 -78 l-25 0 0 210 0 210 145 0 c138 0 145 -1 145 -20z" />
            <path d="M2470 1431 c-3 -133 1 -465 4 -469 3 -2 27 -5 53 -6 l48 -1 3 193 2 192 58 0 c77 0 104 -19 100 -68 -3 -34 -6 -38 -50 -52 l-48 -15 0 -49 c0 -41 7 -60 41 -112 22 -35 47 -68 55 -75 15 -12 104 -16 122 -5 7 4 -8 34 -43 83 -30 42 -55 79 -55 82 1 3 15 15 32 26 48 31 70 80 64 140 -6 53 -25 86 -70 119 -24 19 -45 21 -172 24 -99 3 -144 1 -144 -7z m300 -46 c46 -24 56 -55 51 -148 -1 -11 -21 -39 -46 -63 -51 -49 -51 -46 6 -131 16 -23 29 -44 29 -47 0 -3 -14 -6 -30 -6 -26 0 -36 9 -70 62 -25 38 -40 73 -40 93 0 26 5 33 35 44 58 21 83 93 47 137 -26 33 -55 44 -113 44 -78 0 -89 -14 -89 -115 0 -45 -3 -123 -6 -173 -6 -81 -9 -92 -25 -92 -18 0 -19 12 -19 205 l0 205 120 0 c88 0 129 -4 150 -15z" />
            <path d="M3437 1433 c-11 -11 -8 -473 3 -473 6 0 88 -1 183 -1 l172 0 3 50 3 51 -128 0 -129 0 2 48 2 47 108 5 c105 5 109 6 112 29 2 12 1 34 -2 48 -7 24 -8 24 -113 22 l-107 -2 -1 42 -1 41 123 0 123 0 0 50 0 50 -173 0 c-96 0 -177 -3 -180 -7z m323 -43 c0 -19 -7 -20 -104 -20 -121 0 -136 -8 -136 -72 0 -67 18 -78 122 -78 72 0 88 -3 88 -15 0 -12 -16 -15 -87 -15 -108 0 -123 -10 -123 -83 0 -80 12 -87 142 -87 88 0 108 -3 108 -14 0 -12 -26 -16 -145 -18 -80 -2 -150 -1 -155 0 -6 2 -10 85 -10 213 l0 209 150 0 c143 0 150 -1 150 -20z" />
            <path d="M190 1180 c0 -6 -9 -71 -21 -145 -19 -126 -20 -138 -5 -189 9 -30 31 -74 49 -98 37 -49 124 -98 171 -97 26 0 23 3 -22 16 -64 19 -133 77 -162 138 -16 33 -21 63 -22 123 0 44 2 84 6 90 8 14 20 172 12 172 -3 0 -6 -5 -6 -10z" />
            <path d="M1268 834 c-5 -4 -8 -40 -8 -81 0 -66 2 -73 20 -73 15 0 20 7 20 25 0 21 5 25 29 25 52 0 77 45 48 84 -14 19 -96 34 -109 20z m82 -55 c0 -14 -7 -19 -25 -19 -18 0 -25 5 -25 18 0 25 7 31 31 25 10 -3 19 -14 19 -24z" />
            <path d="M1420 839 c-1 -2 -2 -39 -3 -82 -2 -67 0 -77 16 -77 14 0 17 9 17 59 0 33 3 62 8 64 4 3 18 0 32 -5 l25 -9 -24 -20 c-24 -19 -24 -21 -8 -54 13 -27 23 -35 43 -35 l25 0 -20 28 c-17 25 -18 29 -4 38 18 11 21 60 5 76 -10 10 -112 25 -112 17z" />
            <path d="M1592 824 c-17 -12 -22 -25 -22 -63 0 -56 21 -81 68 -81 44 0 62 23 62 80 0 39 -4 52 -22 64 -12 9 -32 16 -43 16 -11 0 -31 -7 -43 -16z m66 -67 c3 -46 2 -48 -20 -45 -18 2 -24 10 -26 36 -4 45 4 64 25 60 14 -2 19 -15 21 -51z" />
            <path d="M1730 761 c0 -74 2 -81 20 -81 15 0 20 7 20 25 0 21 5 25 29 25 16 0 33 6 38 13 36 46 10 86 -60 94 l-47 6 0 -82z m81 32 c15 -15 3 -33 -22 -33 -13 0 -19 7 -19 24 0 25 20 30 41 9z" />
            <path d="M1880 760 l0 -80 65 0 c53 0 63 3 59 15 -4 10 -20 15 -45 15 -28 0 -39 4 -39 15 0 10 11 15 35 15 28 0 35 4 35 20 0 16 -7 20 -35 20 -22 0 -35 5 -35 13 0 9 12 11 40 9 35 -4 40 -2 40 17 0 19 -6 21 -60 21 l-60 0 0 -80z" />
            <path d="M2030 760 c0 -73 2 -80 20 -80 18 0 20 7 20 64 0 62 0 63 25 58 27 -5 34 -29 10 -38 -32 -13 -2 -84 36 -84 23 0 24 8 2 32 -15 17 -15 19 0 36 41 45 4 92 -70 92 l-43 0 0 -80z" />
            <path d="M2180 820 c0 -16 5 -21 23 -18 21 3 22 0 22 -59 0 -54 2 -63 17 -63 15 0 18 10 18 63 0 60 1 64 23 63 13 -1 22 5 22 14 0 11 -15 16 -62 18 -58 3 -63 1 -63 -18z" />
            <path d="M2320 828 c0 -7 11 -31 25 -53 13 -22 25 -52 25 -67 0 -21 5 -28 20 -28 17 0 19 5 14 25 -4 17 3 40 26 80 30 52 30 55 11 55 -13 0 -27 -12 -37 -31 l-16 -31 -19 31 c-19 31 -49 42 -49 19z" />
            <path d="M2540 760 c0 -75 1 -80 21 -80 20 0 21 4 16 48 -6 43 -5 45 8 28 8 -11 15 -23 15 -28 0 -20 29 -5 38 20 10 25 10 23 11 -20 1 -41 4 -48 21 -48 18 0 20 7 20 80 0 74 -2 80 -21 80 -13 0 -25 -11 -35 -35 -8 -19 -16 -35 -19 -35 -2 0 -12 16 -21 35 -10 22 -23 35 -35 35 -17 0 -19 -8 -19 -80z" />
            <path d="M2767 833 c-6 -10 -57 -143 -57 -149 0 -12 39 -2 44 11 3 9 18 15 36 15 20 0 30 -5 30 -15 0 -8 9 -15 21 -15 23 0 23 0 -14 95 -21 55 -47 79 -60 58z m30 -72 c1 -11 -4 -22 -12 -25 -16 -6 -20 11 -9 39 8 19 17 12 21 -14z" />
            <path d="M2880 760 c0 -74 1 -80 21 -80 18 0 20 5 18 40 -4 55 3 56 30 4 15 -28 31 -44 42 -44 17 0 19 9 19 81 0 71 -2 80 -17 77 -14 -3 -18 -13 -16 -51 1 -26 0 -47 -2 -47 -2 0 -16 23 -31 50 -17 32 -34 50 -46 50 -16 0 -18 -9 -18 -80z" />
            <path d="M3083 823 c-3 -10 -17 -45 -29 -77 -13 -33 -24 -61 -24 -63 0 -11 39 0 44 12 3 9 18 15 36 15 20 0 30 -5 30 -15 0 -9 9 -15 21 -15 16 0 19 4 14 18 -4 9 -14 37 -22 62 -22 64 -32 80 -49 80 -8 0 -17 -8 -21 -17z m35 -78 c-2 -9 -8 -12 -17 -6 -9 6 -11 16 -6 31 7 21 8 21 17 6 5 -9 8 -23 6 -31z" />
            <path d="M3222 824 c-18 -12 -22 -25 -22 -68 0 -61 14 -79 61 -79 37 0 57 18 65 61 6 31 6 32 -30 32 -30 0 -36 -4 -36 -21 0 -15 4 -19 16 -15 10 4 14 1 12 -6 -3 -7 -14 -14 -26 -16 -19 -3 -22 1 -22 32 0 20 0 39 -1 44 0 4 7 12 16 17 12 7 20 6 29 -7 15 -20 32 -23 41 -8 9 14 -32 50 -58 50 -12 0 -33 -7 -45 -16z" />
            <path d="M3360 760 l0 -80 60 0 c47 0 60 3 60 15 0 11 -12 15 -46 15 -37 0 -45 3 -42 17 2 12 13 17 38 17 25 1 36 6 38 19 3 14 -4 17 -38 17 -28 0 -40 4 -38 12 2 6 22 12 44 12 28 1 39 5 39 16 0 11 -14 16 -57 18 l-58 3 0 -81z" />
            <path d="M3510 760 c0 -73 2 -80 20 -80 18 0 19 5 13 45 -6 49 2 59 17 20 11 -30 36 -33 43 -5 9 36 23 21 19 -20 -4 -35 -2 -40 17 -40 21 0 22 4 19 77 -2 64 -6 78 -20 81 -11 2 -23 -9 -36 -34 l-19 -36 -21 36 c-36 62 -52 48 -52 -44z" />
            <path d="M3690 760 l0 -80 60 0 c47 0 60 3 60 15 0 11 -11 15 -40 15 -32 0 -40 4 -40 18 0 15 6 17 35 14 30 -4 35 -1 35 17 0 17 -6 21 -35 21 -22 0 -35 5 -35 13 0 9 12 11 40 9 35 -4 40 -2 40 17 0 19 -6 21 -60 21 l-60 0 0 -80z" />
            <path d="M3840 760 c0 -75 1 -80 21 -80 19 0 21 5 18 44 -5 51 2 50 32 -4 14 -24 30 -40 41 -40 16 0 18 9 18 80 0 75 -1 80 -21 80 -20 0 -21 -4 -16 -45 4 -25 3 -45 -1 -45 -4 0 -18 20 -31 45 -15 29 -31 45 -42 45 -17 0 -19 -9 -19 -80z" />
            <path d="M3992 823 c2 -11 12 -18 26 -17 20 1 22 -4 22 -63 0 -57 2 -63 21 -63 19 0 21 5 17 63 -3 59 -2 62 20 59 17 -3 22 2 22 17 0 19 -6 21 -66 21 -57 0 -65 -2 -62 -17z" />
            <path d="M1070 761 c-20 -49 -79 -107 -134 -131 -51 -23 -117 -29 -201 -17 -27 4 -81 11 -120 16 -178 25 -181 17 -5 -15 211 -37 274 -38 338 -5 68 35 151 138 140 173 -2 6 -10 -3 -18 -21z" />
        </g>
    </svg>
);
