import ModalInputField from "./ModalInputField";
import ConvoModal from "./ConvoModal";
import { ModalFooter, ModalFooterActions } from "@twilio-paste/modal";
import { Box, ModalBody, Switch } from "@twilio-paste/core";
import { Button } from "@twilio-paste/button";
import React, { useEffect, useState } from "react";
import { User } from "@twilio/conversations";
import { getCallRecordingUrl, getCalls, getRecording, updateFriendlyName } from "../../api";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators, AppState } from "../../store";

interface CallsModalProps {
  isModalOpen: boolean;
  handleClose: () => void;
  user?: User;
}

const CallsModal: React.FC<CallsModalProps> = (
  props: CallsModalProps
) => {

    const [calls, setCalls] = useState([]);
    const username = localStorage.getItem("username");
    const password = localStorage.getItem("password");
    const a = localStorage.getItem("a");
    const s = localStorage.getItem("s");
  const identity = props.user?.identity ?? "";

  const dispatch = useDispatch();

  const [friendlyName, setFriendlyName] = useState(
    props.user?.friendlyName ?? ""
  );

  const { updateTimeFormat } = bindActionCreators(actionCreators, dispatch);
  const use24hTimeFormat = useSelector(
    (state: AppState) => state.use24hTimeFormat
  );
  const [on, setOn] = useState(use24hTimeFormat);

  const handleTimeFormatUpdate = async () => {
    updateTimeFormat(on);
  };

    const loadCalls = async () => {
        if (username && password) {
            getCalls(username, password)
                .then((calls) => {
                    setCalls(calls);
                    console.log(calls);
                })
                //.catch(() => {
                //});
        }
    }

    const getVoicemail = async (call: any) => {
        if (username && password) {
            debugger;
            getCallRecordingUrl(call.sid, username, password)
                .then((url) => {
                    if (url) {
                        //var url = 'https://api.twilio.com/2010-04-01/Accounts/AC7a2e5c9df12e78ef78b8c42aa292d400/Recordings/RE31a7c016a0f8df021b5da7505909ecbd.mp3';
                        getRecording(call.from.slice(1), url, username, password)
                            .then((recording) => {
                                console.log(recording);
                            })
                        // .catch(() => {
                        //});
                    }
                });
        }
    }

    useEffect(() => {
        loadCalls();
    }, []);


  return (
    <>
      <ConvoModal
        handleClose={() => props.handleClose()}
        isModalOpen={props.isModalOpen}
        title="Calls"
        modalBody={
            <ModalBody>
                <ul className="list-group">
                    {calls.map((call: any) => {
                        // debugger;
                        if (call.direction == "inbound" && call.from != '+18647292780') {
                            const callTell = "tel:" + call.to.slice(2) + ",," + call.from.slice(2);
                            return (
                                <li className="list-group-item">
                                    <div className="d-grid gap-2 d-md-flex justify-content-md-center">
                                        <span>{new Date(call.startTime).toLocaleString()}</span> <span>{call.from}</span>
                                    </div>
                                    <div className="d-grid gap-2 d-md-flex justify-content-md-center">
                                        <button type="button" className="btn btn-secondary" onClick={() => { getVoicemail(call); }}>Voicemail</button>
                                        <button type="button" className="btn btn-success" onClick={() => { window.open(callTell); }}>Call</button>
                                    </div>
                                </li>
                            )
                        }
                    })}
                </ul>
            </ModalBody>
        }
        modalFooter={
          <ModalFooter>
            <ModalFooterActions justify="start">
            </ModalFooterActions>
          </ModalFooter>
        }
      />
    </>
  );
};

export default CallsModal;
