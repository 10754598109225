import React, { useState } from "react";
import { Client } from "@twilio/conversations";

import ConversationTitleModal from "../modals/ConversationTitleModal";
import { addChatParticipant, addConversation, addNonChatParticipant } from "../../api";
import { Button } from "@twilio-paste/button";
import { PlusIcon } from "@twilio-paste/icons/esm/PlusIcon";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../store";
import ConversationTenantProspectModal from "../modals/ConversationTenantProspectModal";
import { SMS_PREFIX } from "../../constants";
import { unexpectedErrorNotification } from "../../helpers";

interface NewConvoProps {
    client?: Client;
    collapsed: boolean;
}

const CreateTenantProspectConversationButton: React.FC<NewConvoProps> = (
    props: NewConvoProps
) => {
    const dispatch = useDispatch();
    const { updateCurrentConversation, addNotifications, updateParticipants } =
        bindActionCreators(actionCreators, dispatch);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleOpen = () => setIsModalOpen(true);

    return (
        <>
            <div style={{ margin: "10px 0px" }}>
            <Button fullWidth variant="secondary" onClick={handleOpen}>
                <PlusIcon decorative={false} title="Add convo" />
                {!props.collapsed ? "Quick Conversation" : null}
            </Button>
            </div>
            <ConversationTenantProspectModal
                isModalOpen={isModalOpen}
                onCancel={() => {
                    setIsModalOpen(false);
                }}
                onSave={async (phone: string, name: string, property: string) => {
                    const title = phone + ' - ' + name + ' - ' + property
                    const phoneProxy = '18665367437'
                    const chatParticipant1 = 'brandon@forgepropertymanagement.com';
                    const chatParticipant2 = 'teresa@forgepropertymanagement.com';

                    const message = 'Hi ' + name + ', this is Forge Property Management about the showing request at ' + property + '. You should have received an email with details about the property. We have a few questions we would like to discuss, so we can get a showing scheduled. Would you like to answer these via text, email or phone? We look forward to the showing and getting you moved into the property soon.';


                    const convo = await addConversation(
                        title,
                        updateParticipants,
                        props.client,
                        addNotifications
                    );

                    try {
                        await addNonChatParticipant(
                            SMS_PREFIX + phone,
                            SMS_PREFIX + phoneProxy,
                            convo,
                            addNotifications
                        );

                    } catch (e) {
                        console.log(e);
                    }

                    try {
                        await addChatParticipant(chatParticipant1.trim(), convo, addNotifications);
                    } catch (e) {
                        //console.log(e);
                    }

                    try {
                        await addChatParticipant(chatParticipant2.trim(), convo, addNotifications);
                    } catch (e) {
                        //console.log(e);
                    }


                    try {
                        const newMessageBuilder = convo.prepareMessage().setBody(message);
                        const messageIndex = await newMessageBuilder.build().send();


                        await convo.advanceLastReadMessageIndex(messageIndex ?? 0);
                    } catch (e) {
                        unexpectedErrorNotification(e.message, addNotifications);
                        throw e;
                    }


                    setIsModalOpen(false);
                    updateCurrentConversation(convo.sid);
                }}
            />
        </>
    );
};

export default CreateTenantProspectConversationButton;
