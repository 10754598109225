import React, { Dispatch, SetStateAction } from "react";
import { Label } from "@twilio-paste/label";
import { Input } from "@twilio-paste/input";
import { Select, Option } from '@twilio-paste/core/select';
import { HelpText } from "@twilio-paste/help-text";
import { Button } from "@twilio-paste/button";
import { HideIcon } from "@twilio-paste/icons/esm/HideIcon";
import { ShowIcon } from "@twilio-paste/icons/esm/ShowIcon";
import { InputType } from "../../types";
import styles from "../../styles";

enum PrefixType {
  SMS = "SMS",
  WhatsApp = "WhatsApp",
}

function getPrefixType(prefixType: string | undefined) {
  switch (prefixType) {
    case PrefixType.SMS:
      return "+";
    case PrefixType.WhatsApp:
      return "WhatsApp +";
    default:
      return undefined;
  }
}

interface ModalSelectProps {
  label: string;
  input: string;
    onChange: (value: string) => void;
    options: Array<ModalSelectOptions> 
  onBlur?: () => void;
  error?: string;
  placeholder?: string;
  readonly?: boolean;
  maxLength?: number;
  help_text?: string;
  prefixType?: string;
  inputType?: InputType;
  showPassword?: boolean;
  isFocused?: boolean;
  setShowPassword?: Dispatch<SetStateAction<boolean>>;
    id?: string;
}

interface ModalSelectOptions {
    value: string,
    text: string
}

const ModalSelectField: React.FC<ModalSelectProps> = (props: ModalSelectProps) => {
  const prefixType = getPrefixType(props.prefixType);


    function buildOptions() {
        const options: any = [];

        options.push(<Option value=""> </Option>);
        if (props.options) {
            for (let i = 0; i < props.options.length; i++) {
                options.push(<Option value={props.options[i].value}>{props.options[i].text}</Option>);
            }
        }
        return (
            <>
                {options}
            </>
        )
    }


  return (
    <>
      <Label htmlFor="modal-input">
        <div style={styles.modalInputLabel}>{props.label}</div>
      </Label>
      <Select 
        id={props.id}
        autoFocus={props.isFocused ?? false}
        //type={props.inputType ?? InputType.Text}
        value={props.input}
        placeholder={props.placeholder}
              onChange={(e) => { debugger;  props.onChange(e.currentTarget.value) }}
        //readOnly={props.readonly ?? false}
        hasError={!!props.error}
        onBlur={props.onBlur}
        //maxLength={props.maxLength}
        insertBefore={prefixType}
        insertAfter={
          props.showPassword !== undefined && (
            <>
              <Button
                variant="link"
                onClick={() => {
                  if (props.setShowPassword !== undefined) {
                    props.setShowPassword(!props.showPassword);
                  }
                }}
              >
                {props.showPassword ? (
                  <ShowIcon
                    decorative={true}
                    size="sizeIcon20"
                    color="colorTextWeak"
                  />
                ) : (
                  <HideIcon
                    decorative={true}
                    size="sizeIcon20"
                    color="colorTextWeak"
                  />
                )}
              </Button>
            </>
          )
        }
          >
              {buildOptions()}
      </Select>
      {props.error && (
        <HelpText id="error_help_text" variant="error">
          {props.error}
        </HelpText>
      )}
      {!props.error && props.help_text && (
        <HelpText id="error_help_text">{props.help_text}</HelpText>
      )}
    </>
  );
};

export default ModalSelectField;
